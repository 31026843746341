var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mw-100",staticStyle:{"width":"26.25rem","margin":"0 auto"}},[_c('div',{staticClass:"card text-center"},[_c('div',{staticClass:"card-body mx-6 mb-6 mt-5 p-0"},[_c('h1',{staticClass:"card-title mb-5"},[_vm._v("Activation Code")]),_vm._m(0),_c('form',{staticClass:"text-left mw-1000",attrs:{"id":"activateForm","autocomplete":"off","novalidate":"novalidate"},on:{"submit":function($event){$event.preventDefault();return _vm.activate.apply(null, arguments)}}},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.email.$model),expression:"$v.form.email.$model"}],ref:"email",staticClass:"form-control form-control-lg mb-5 text-center",class:{
                'is-valid': !_vm.$v.form.email.$error && _vm.$v.form.email.$dirty,
                'is-invalid': _vm.$v.form.email.$error
              },attrs:{"type":"email","id":"email","placeholder":"Email","autocomplete":"off"},domProps:{"value":(_vm.$v.form.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.email, "$model", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"passcode"}},[_vm._v("Activation Code")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.passcode.$model),expression:"$v.form.passcode.$model"}],ref:"passcode",staticClass:"form-control form-control-lg mb-4 text-center",class:{
                'is-valid': !_vm.$v.form.passcode.$error && _vm.$v.form.passcode.$dirty,
                'is-invalid': _vm.$v.form.passcode.$error
              },attrs:{"type":"text","id":"passcode","placeholder":"Activation Code","autocomplete":"off"},domProps:{"value":(_vm.$v.form.passcode.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.passcode, "$model", $event.target.value)}}})])]),_vm._m(1),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.password.$model),expression:"$v.form.password.$model"}],staticClass:"form-control form-control-lg mb-5 text-center",class:{
                  'is-valid': !_vm.$v.form.password.$error && _vm.$v.form.password.$dirty,
                  'is-invalid': _vm.$v.form.password.$error
                },attrs:{"type":"password","id":"password","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.$v.form.password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.password, "$model", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"sr-only",attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password")]),_c('p',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.form.confirmPassword.$model),expression:"$v.form.confirmPassword.$model"}],staticClass:"form-control form-control-lg mb-5 text-center",class:{
                  'is-valid': !_vm.$v.form.confirmPassword.$error && _vm.$v.form.confirmPassword.$dirty,
                  'is-invalid': _vm.$v.form.confirmPassword.$error
                },attrs:{"type":"password","id":"confirmPassword","placeholder":"Confirm Password","autocomplete":"off"},domProps:{"value":(_vm.$v.form.confirmPassword.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.confirmPassword, "$model", $event.target.value)}}})])])]),_c('p',{attrs:{"align":"center"}},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"type":"submit","disabled":_vm.$v.form.$invalid}},[_vm._v(" Submit ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" An Activation Code was sent to the email you provided. Please re-enter your email address and Activation Code here along with a password to create your account. "),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-4 text-center"},[_vm._v(" Passwords must be at least 6 characters, no more than 20 characters, and must include at least one upper case letter, one lower case letter, and one numeric digit. ")])])])
}]

export { render, staticRenderFns }