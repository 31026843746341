<template>
  <div class="mw-100" style="width: 26.25rem; margin: 0 auto">
    <div class="card text-center">
      <div class="card-body mx-6 mb-6 mt-5 p-0">
        <h1 class="card-title mb-5">Activation Code</h1>
        <div>
            An Activation Code was sent to the email you provided. Please re-enter your email
            address and Activation Code here along with a password to create your account.
            <br><br>
        </div>

        <form id="activateForm" class="text-left mw-1000" autocomplete="off" @submit.prevent="activate" novalidate="novalidate">

          <div class="col-sm-12">
            <div class="form-group">
              <label for="email" class="sr-only">Email</label>
              <input 
                type="email" 
                id="email" 
                ref="email"
                v-model="$v.form.email.$model"
                placeholder="Email"
                autocomplete="off" 
                class="form-control form-control-lg mb-5 text-center"
                :class="{
                  'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                  'is-invalid': $v.form.email.$error
                }"
              />
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group">
              <label for="passcode" class="sr-only">Activation Code</label>
              <input 
                type="text" 
                id="passcode"
                ref="passcode"
                v-model="$v.form.passcode.$model" 
                placeholder="Activation Code" 
                autocomplete="off" 
                class="form-control form-control-lg mb-4 text-center"
                :class="{
                  'is-valid': !$v.form.passcode.$error && $v.form.passcode.$dirty,
                  'is-invalid': $v.form.passcode.$error
                }"
              />
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group">
              <div class="mb-4 text-center">
              Passwords must be at least 6 characters,
              no more than 20 characters,
              and must include at least one upper case letter,
              one lower case letter, and one numeric digit.
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="form-group">
              <label for="password" class="sr-only">Password</label>
                <input 
                  type="password" 
                  id="password" 
                  v-model="$v.form.password.$model" 
                  placeholder="Password" 
                  autocomplete="off" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.password.$error && $v.form.password.$dirty,
                    'is-invalid': $v.form.password.$error
                  }"
                />
           </div>
          </div>
          
          <div class="col-sm-12">
            <div class="form-group">
              <label for="confirmPassword" class="sr-only">Confirm Password</label>
              <p>
                <input 
                  type="password" 
                  id="confirmPassword" 
                  v-model="$v.form.confirmPassword.$model"
                  placeholder="Confirm Password" 
                  autocomplete="off" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.confirmPassword.$error && $v.form.confirmPassword.$dirty,
                    'is-invalid': $v.form.confirmPassword.$error
                  }"
                />
              </p>
            </div>
          </div>

          <p align="center">
            <button type="submit" :disabled="$v.form.$invalid" class="btn btn-primary btn-block">
              Submit
            </button>
          </p> 
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { email } from 'vuelidate/lib/validators';
import { helpers } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';
import { sameAs } from 'vuelidate/lib/validators';

import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

// Passwords must be at least 6 characters,
// no more than 20 characters,
// and must include at least one upper case letter,
// one lower case letter, and one numeric digit.
const pwrules = helpers.regex('alpha', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);

export default {
  name: "ActivationView",

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      form: {
        email: null,
        passcode: null,
        password: null,
        confirmPassword: null
      }
    }
  },

  validations: {
    form: {
      email: {
        email,
        required
      },
      passcode: {
        minLength: 7,
        required
      },
      password: { 
        required, 
        pwrules
      },
      confirmPassword: { 
        sameAsPassword: sameAs('password')
      }
    }
  },

  methods: {
    async activate() {
      this.pcSpinnerShow();

      // Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "resetpw".
      const token = await this.$recaptcha('resetpw')

      this.apiActivate(this.form.email, this.form.passcode, this.form.password, token)
      .then(() => {
        this.pcModal("Your account has been created")
        .then(() => { this.$router.push({ name: 'SignInRoute' }) })
        .catch(() => {})
      })
      .catch(() => {
        this.pcModal("Invalid email/activation code combination")
        .catch(() => {})      
      })
      .finally(() => {
        this.pcSpinnerHide();
      })
    }
  },

  mounted() {
    if ("email" in this.$route.params) {
      this.form.email = this.$route.params.email;
      this.$refs.passcode.autofocus = true
    } else {
      this.$refs.email.autofocus = true
    }
  }
}
</script>
